import React, { useState } from 'react';
import RoundIcon from 'components/common/RoundIcon';
import PopperWrapper from 'components/common/PopperWrapper';
import useStore from 'store/timeline';
import EmotionSelector from 'components/common/EmotionSelector';
import useGlobalStore from 'store/global';
import styles from './CreatePostBox.module.scss';
import Icon, { LiveIcon } from 'components/common/Icon';
import { useRouter } from 'next/router';
import { track } from '@amplitude/analytics-browser';
import classNames from 'classnames';

export default function CreatePostBox({
  isProfilePage = false,
}: {
  isProfilePage?: boolean;
}): JSX.Element {
  const currentUser = useStore((state) => state.currentUser);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const showCreatePostDialog = useGlobalStore(
    (state) => state.showCreatePostDialog
  );
  const router = useRouter();
  const showLogin = useGlobalStore((state) => state.showLogin);
  const createPostClick = () => {
    track('Start Create Post');
    if (!currentUser) {
      showLogin({});
      return;
    }
    showCreatePostDialog({
      open: true,
    });
  };

  const liveClick = () => {
    if (!currentUser) {
      showLogin({});
      return;
    }
    void router.push(`/livestream/new`);
  };

  return (
    <div
      className={classNames(styles.createPostBox, {
        [styles.isProfilePage]: isProfilePage,
      })}
    >
      {currentUser && (
        <PopperWrapper
          id="emotion-selector-timeline"
          buttonEl={
            <div className={styles.avatar}>
              <RoundIcon
                user={currentUser}
                width={48}
                height={48}
                hasRing
                emotion={currentUser?.emotion}
                showAddBtn
              />
            </div>
          }
          contentEl={<EmotionSelector />}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          hover
        />
      )}
      <div className={styles.inputBox} role="presentation">
        <div
          className={styles.textInputWrapper}
          onClick={() => createPostClick()}
        >
          <div className={styles.textInput}>
            <p>何を投稿する？</p>
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.iconWrapper} onClick={() => createPostClick()}>
            <div className={styles.iconGroup}>
              <div className={styles.icon}>
                <Icon name="add-video" width={24} height={24} />
              </div>
            </div>
            <span className={styles.text}>動画</span>
          </div>
          <div className={styles.iconWrapper} onClick={() => createPostClick()}>
            <div className={styles.iconGroup}>
              <div className={styles.icon}>
                <Icon name="add-photo" width={24} height={24} />
              </div>
            </div>
            <span className={styles.text}>画像</span>
          </div>
          <div className={styles.iconWrapper} onClick={() => createPostClick()}>
            <div className={styles.iconGroup}>
              <div className={styles.icon}>
                <Icon name="add-audio" width={24} height={24} />
              </div>
            </div>
            <span className={styles.text}>音声</span>
          </div>
          {currentUser?.has_live_permission && (
            <div className={styles.iconWrapper} onClick={() => liveClick()}>
              <div className={styles.iconGroup}>
                <div className={styles.icon}>
                  <LiveIcon width={24} height={24} stokeWidth={1.5} />
                </div>
              </div>
              <span className={styles.text}>LIVE</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
