import axios from 'axios';
import { useInfiniteQuery } from 'react-query';
import { useEffect, useMemo, useState } from 'react';
import { AnimatePresence } from 'framer-motion';

import PostItem from 'components/Post';
import {
  GetTimelinePostsResult,
  Post,
  PostParentKey,
} from 'common/interfaces/api';
import useStore from 'store/timeline';
import useGlobalStore from 'store/global';
import Icon from 'components/common/Icon';
import InfiniteScroll from 'components/common/InfiniteScroll';

import styles from './TimelineSection.module.scss';
import { PostsLoader } from 'components/common/PreloadContentLoader';
import { useMountedDelay } from 'common/utils/hooks/useDidMount';

export type Props = {
  apiUrl: string;
  queryKey: string | (string | number)[];
  queryParamKey?: string;
  getNextPageParam?: (
    lastPage: GetTimelinePostsResult,
    pages: GetTimelinePostsResult[]
  ) => string | number;
  noResultMessage?: string;
  disableInfiniteScroll?: boolean;
  hideNoResult?: boolean;
  defaultPageParam?: number | string;
  subQuery?: { key: string; value: string };
};
export default function TimelineSection({
  apiUrl,
  queryKey,
  queryParamKey = 'last_post_id',
  getNextPageParam = (lastPage) => {
    const posts = lastPage?.data?.posts;
    if (posts && posts.length > 0) {
      const lastPost = posts[posts.length - 1];
      return lastPost.id;
    }
  },
  noResultMessage = '投稿はありません',
  disableInfiniteScroll = false,
  hideNoResult = false,
  defaultPageParam = 0,
  subQuery,
}: Props): JSX.Element {
  const shouldRefetchPosts = useStore((state) => state.shouldRefetchPosts);
  const isAuthInit = useGlobalStore((state) => state.isAuthInit);
  const currentAuth = useGlobalStore((state) => state.currentAuth);
  const mounted = useMountedDelay();
  const usingMounted = currentAuth ? true : mounted;
  const {
    isLoading,
    data,
    fetchNextPage,
    refetch,
    isFetchingNextPage,
    isFetched,
  } = useInfiniteQuery(
    queryKey,
    async ({ pageParam = defaultPageParam }) => {
      if (!isAuthInit || !usingMounted) return Promise.reject();
      const query = `${queryParamKey}=${String(pageParam)}`;
      const filterParam =
        queryKey && queryKey[0] === PostParentKey.UnReadPosts
          ? '&unread=true'
          : '';
      const subQueryParam = subQuery
        ? `&${subQuery.key}=${subQuery.value}`
        : '';
      const url = `${apiUrl}?${query}${filterParam}${subQueryParam}`;
      return (await axios.get(url)).data as GetTimelinePostsResult;
    },
    {
      getNextPageParam,
      // TODO: somehow enabled is not working as intended
      // useInfiniteQuery still runs when enabled is false
      // this is currently handled by only shows this component when isAuthInit is true
      enabled: isAuthInit && usingMounted,
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 15 * 60 * 1000,
      cacheTime: 15 * 60 * 1000,
    }
  );

  useEffect(() => {
    if (shouldRefetchPosts) {
      void refetch();
    }
  }, [shouldRefetchPosts, refetch]);

  const pages = data?.pages;
  const posts: Post[] = useMemo(
    () =>
      pages
        ? pages.reduce<Post[]>((curr, page) => {
            const posts = page.data?.posts || [];
            return curr.concat(posts);
          }, [])
        : [],
    [pages]
  );

  let noPostPlaceholder;
  if (!isLoading && posts.length === 0 && !hideNoResult) {
    noPostPlaceholder = (
      <div className={styles.noPost}>
        <Icon name="empty" width={96} height={96} />
        <div dangerouslySetInnerHTML={{ __html: noResultMessage }}></div>
      </div>
    );
  }

  // Animation
  const [doUseAnimation, setUseAnimation] = useState(false);

  // Prevent animation from playing on first load
  useEffect(() => {
    let timeout;
    if (!isLoading) {
      timeout = setTimeout(() => setUseAnimation(true));
    }
    return () => {
      clearTimeout(timeout);
      setUseAnimation(false);
    };
  }, [isLoading]);

  if (!queryKey || !queryKey[0]) return <></>;
  const postsRender = (
    <AnimatePresence initial={false}>
      {posts.map((post, index) => {
        return (
          <div key={`${post?.id}-${index}`} className={styles.post}>
            <PostItem
              post={post}
              shouldAnimate={doUseAnimation}
              parent={String(queryKey[0])}
              hasFollowBtn
              isPinned={
                queryKey[0] === PostParentKey.ProfilePosts && post.is_pinned
              }
            />
          </div>
        );
      })}
    </AnimatePresence>
  );

  return (
    <div className={styles.timelineWrapper}>
      <InfiniteScroll
        fetchNextPage={fetchNextPage}
        disabled={disableInfiniteScroll}
        isLoading={isFetchingNextPage}
      >
        {isFetched ? postsRender : <PostsLoader />}
      </InfiniteScroll>
      {noPostPlaceholder}
    </div>
  );
}
