import { useEffect, useState } from 'react';

export const useMounted = () => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return mounted;
};

export const useMountedDelay = () => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    const delay = setTimeout(() => {
      setMounted(true);
    }, 500);
    return () => {
      delay && clearTimeout(delay);
      setMounted(true);
    };
  }, []);

  return mounted;
};
